/*@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,500;1,700&display=swap");
*/

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('assets/font/Montserrat-VariableFont_wght.ttf') format('ttf')
}

* {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-montserrat: Montserrat;

  /* font sizes */
  --font-size-5xl: 24px;
  --font-size-lg: 18px;
  --font-size-7xl: 26px;
  --font-size-41xl: 60px;
  --font-size-11xl: 30px;
  --font-size-12xl-3: 31.3px;

  /* Colors */
  --white: #e1e8dc;
  --black-v2: #191b18;
  --primary: #8dcf61;
  --primary-hover: #80bd58;
  --primary-click: #71aa4c;
  --color-gainsboro: rgba(217, 217, 217, 0.75);
  --secondary: #00b791;
  --grey: #373d31;

  /* Gaps */
  --gap-21xl: 40px;
  --gap-xl: 20px;
  --gap-11xl: 30px;
  --gap-3xs: 10px;
  --gap-mini: 15px;
  --gap-7xs: 6px;
  --gap-56xl: 75px;
  --gap-45xl: 64px;
  --gap-61xl: 80px;

  /* Paddings */
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-mini: 15px;
  --padding-124xl: 143px;
  --padding-74xl: 93px;
  --padding-lg: 18px;
  --padding-xl: 20px;
  --padding-81xl: 100px;

  /* Border radiuses */
  --br-xl: 20px;
  --br-26xl: 45px;
  --br-31xl: 50px;
}