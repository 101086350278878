  
  .title {
    font-size: 2rem; /* Taille de la police pour le titre */
    font-weight: bold; /* Mettre en gras */
    text-align: center; /* Centrer le texte */
    margin-bottom: 1rem; /* Marge en bas du titre */
  }
  
  .faqContainer {
    display: flex;

    flex-direction: column;
    gap: 2rem; /* Espacement entre chaque FAQ */
  }
  
  .faqItem {
    width: 100%;
    border-bottom: 1px solid #000000; /* Bordure en bas */
    margin-bottom: 2rem;
  }
  
  #lastQuestion {
    border-bottom : 0px;
  }

  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem; /* Taille de la police pour les questions */
    cursor: pointer; /* Curseur pointeur pour indiquer que c'est cliquable */
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    text-align: left;
    gap: 20px;
    font-weight: 500;
  }

  
  .chevron {
    font-size: 1.5rem; /* Taille de la police pour le chevron */
    padding-right: 2%;
  }

  .chevron img{
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .answer {
    font-size: 1.25rem; /* Taille de la police pour les réponses */
    margin-bottom: 1rem;
    text-align: left;
    text-justify: auto;
  }


  @media (max-width: 1024px) {

  }
  
  @media (max-width: 600px) {

    .faqItem{ 
      width: 90%;
    }

    .chevron {
      font-size: 1rem; /* Taille de la police pour le chevron */
    }

    .question {
      font-size: 1rem; /* Taille de la police pour les questions */
    }

    .answer {
      font-size: 0.825rem; /* Taille de la police pour les réponses */
    }
  }
  