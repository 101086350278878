.runningManContainer {
  z-index: 2;
}

.runningMan {
  display: flex;
  align-items: center;
  gap: 70px;
}

.skateboardingImage {
  width: 180px;
  height: auto;
  z-index: 1;
  transform: rotate(3deg);
}

.windLines {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 50px;
  background-color: red;
}

.windLine {
  height: 3px;
  width: 50px;
  background-color: grey;
}

#windLine1, #windLine3 {
  margin-left: 10px;
}

#windLine2 {
  width: 30px;
}

@media (max-width: 1024px) {
  .skateboardingImage {
    width: 150px;
  }
}

@media (max-width: 600px) {
  .skateboardingImage {
    width: 110px;
  }
}