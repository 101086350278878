.lastCta {
  width: 100vw;

  box-sizing: border-box;
  padding: 3%;
  
  background-color: var(--secondary);
}

.lastCtaContent {
  height: 100%;
  max-width: 1400px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.lastCtaText{
  display: flex;
  flex-direction: column;
  width: 45%;
  text-align: left;
}

.lastCtaTitle{
  font-size: 2rem;
  font-weight: bold;
  padding: 0;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.lastCtaDesc{
  font-size: 1.125rem;
  font-weight: 300;
}

@media (max-width: 1400px) {
  .lastCtaContent{
    flex-direction: column;
    height: 100%;
    padding: 4%;
  }

  .lastCtaText{
    width: 80%;
    text-align: center;
  }

  .lastCtaDesc{
    margin-bottom: 40px;
  }
}

/* Responsive design for phones */
@media (max-width: 600px) {

  .lastCtaContent{
    padding-top: 8%;
    padding-bottom: 15%;
  }

  .lastCtaText{
    width: 100%;
  }

  .lastCtaTitle{
    font-size: 1.25rem;
  }

  .lastCtaDesc{
    font-size: 0.875rem;
  }
}