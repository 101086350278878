:root {
  --font-size-5xl: 24px; /* Example value, adjust as needed */
  --gap-21xl: 20px; /* Example value, adjust as needed */
  --primary-color: #77D14E;
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-5xl);
  height: 10vh;
  margin-top: 1rem;
}

.logoFeelioIcon1 {
  width: 186px;
  height: 56px;
  object-fit: cover;
}


.sectionParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-21xl);
}

.section {
  position: relative;
  text-decoration: none;
  color: #000000;
  font-weight: 400;
  cursor: pointer;
}

.section::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: black;
  transition: width 0.3s ease-in-out;
}

.section:hover::after {
  width: 100%;
}


.burgerMenu {
  display: none;
  font-size: 30px;
  cursor: pointer;
}

.burgerMenuContent {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--white);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.burgerMenuContent.open {
  transform: translateX(0);
  display: flex;
  box-sizing: border-box;
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 4%;
}

.burgerMenuHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
}

.closeMenu {
  font-size: 30px;
  cursor: pointer;
}

.burgerMenuLinks {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10%;
}

.separator {
  width: 100%;
  height: 3px;
  background-color: var(--grey);
  border: none;
  margin: 30px 0;
}

@media (max-width: 1024px) {

  .navbar{
    margin-top: 0.2rem;
  }

  .sectionParent {
    display: none;
  }

  .burgerMenu {
    display: block;
  }

  .section {
    font-size: larger;
    text-align: left;
  }
}

@media (max-width: 600px) {
  .logoFeelioIcon1 {
    width: 100px;
    height: 30px;
  }

  .section {
    font-size: 1.5rem;
    width: 90vw;
    height: 4vh;
  }
}
