.footer {
    background-color: var(--grey);
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    font-size: var(--font-size-5xl);
    color: var(--white);
    padding: 3% 6% 1% 6%;
}

.footerContent {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 2%;
    width: 100%;
    max-width: 1400px;
    margin: auto;
}

.footerPageLink {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.logoFeelioIcon {
    width: 122px;
    height: 36.7px;
}

.footerSubContent1, .footerSubContent2 {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.section {
    position: relative;
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: none;
    color: var(--white);
    cursor: pointer;
}

.section::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: var(--white);
    transition: width 0.3s ease-in-out;
  }
  
  .section:hover::after {
    width: 100%;
  }

.legalContent{
    display: flex;
    gap: 1rem;
}

.sectionLegal {
    font-size: 0.875rem;
    cursor: pointer;
    text-decoration: underline;
    color: var(--white);
}

.socialMedia {
    display: flex;
    gap: 1rem;
}

.socialIcon img {
    width: 24px;
    height: 24px;
    filter: invert(1);
}

.socialIcon:hover img {
    filter: invert(0.75);
}

.copyrightFeelio {
    font-size: 1.2rem;
    font-weight: 100;
    text-align: center;

    border-top: 1px solid rgba(255, 255, 255, 0.3); 
    padding-top: 20px; 
    padding-bottom: 20px;
}


/* Responsive design for tablets */
@media (max-width: 1024px) {
    .sectionLegal {
        font-size: 0.875rem;
    }

    .footerSubContent1, .footerSubContent2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
    }
}
  
/* Responsive design for phones */
@media (max-width: 600px) {

    .footer {
        padding-top: 10%;
        padding-bottom: 8%;
    }

    .footerContent {
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .copyrightFeelio{
        font-size: 0.875rem;
        height: 30px;

        border-top: none; 
        padding-top: 0px; 
        padding-bottom: 0px;
    }

    .logoFeelioIcon {
        width: 100px;
        height: 30px;
    }

    .sectionLegal {
        font-size: 0.75rem;
        cursor: pointer;
        text-decoration: underline;
        color: var(--white);
    }
}

@media (max-width: 400px) {
    .footerPageLink {
        display: none;
    }
}