.buttonText {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 24px;
  font-weight: 500;
}

.icon {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.button {
  position: relative;
  overflow: hidden;
  border-radius: var(--br-xl);
  background-color: var(--primary);
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  box-sizing: border-box;
  gap: var(--gap-mini);
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--black-v2);
  font-family: var(--font-monsterrat);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  box-shadow: 0 0 15px 5px rgba(119, 209, 78, 0.5);
}

.button::before,
.button::after {
  content: '';
  position: absolute;
  top: -100%;
  left: -100%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.2);
  transform: rotate(45deg);
  transition: transform 0.5s ease-in-out;
  pointer-events: none;
}

.button::before {
  animation: diagonal-stripes 6s infinite;
}

.button::after {
  animation: diagonal-stripes 6s infinite 3s;
}

.button:hover {
  background-color: var(--primary-hover);
}

.button:active {
  background-color: var(--primary-click);
}

@keyframes diagonal-stripes {
  0% {
    top: -100%;
    left: -100%;
  }
  25% {
    top: 50%;
    left: 50%;
  }
  50% {
    top: 200%;
    left: 200%;
  }
  100% {
    top: 200%;
    left: 200%;
  }
}

.button:hover::before,
.button:hover::after {
  background: rgba(255, 255, 255, 0.4); /* Make the stripes more visible on hover */
}



@media (max-width: 600px) {
  .buttonText {
    font-size: 18px;
  }

  .button {
    width: 300px !important;
    height: 50px !important;
  }
}
