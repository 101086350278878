:root {
  --primary-color: #77D14E;
  --font-size-5xl: 1rem;
  --black-v2: #000000;
  --font-monsterrat: 'Montserrat', sans-serif;
  --br-xl: 8px;
  --grey: #ccc;
  --white: #ffffff;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: var(--white);
  width: 43.75rem;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  background-color: var(--white);
  z-index: 1;
  border-radius: 0 8px 0 0;
}

.imageContainer {
  width: 100%;
  height: 19.75rem;
  background: url('../../../public/imageModal.webp') no-repeat center center/cover;
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0 0;
}

.imageOverlay {
  width: 100%;
  height: 10.625rem;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.overlayTitle {
  font-family: var(--font-monsterrat);
  font-size: 1.5rem;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

.overlayDescription {
  font-family: var(--font-monsterrat);
  font-size: 1rem;
  margin: 0 1rem;
}

.inputContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.inputWrapper {
  position: relative;
}

.input {
  height: 1.25rem;
  padding: 0.75rem;
  border: 2px solid var(--grey);
  border-radius: 5px;
  outline: none;
  font-family: var(--font-monsterrat);
  transition: border-color 0.3s ease;
  background: var(--white);
}

.inputFirstName{
  width: 10rem;
}

.inputEmail{
  width: 18rem;
}

.input:not(:placeholder-shown) + .label,
.labelFilled {
  top: 0rem !important;
  left: 0.75rem !important;
  font-size: 0.75rem !important;
  background: var(--white) !important;
  padding: 0 0.25rem !important;
}


.label {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  transform: translateY(-50%);
  transition: 0.3s ease all;
  pointer-events: none;
  font-family: var(--font-monsterrat);
  color: var(--grey);
  background: var(--white);
  padding: 0 0.25rem;
}

.modalButton {
  padding: 0.75rem 1.5rem;
  border-radius: var(--br-xl);
  background-color: var(--primary-color);
  font-size: var(--font-size-5xl);
  color: var(--black-v2);
  font-family: var(--font-monsterrat);
  font-weight: 500;
  border: none;
  width: 40%;
  margin-bottom: 1.5rem;
}

.modalButton:hover {
  background-color: var(--primary-hover);
}

.modalButton:active {
  background-color: var(--primary-click);
}

@media (max-width: 600px) {
  .overlayTitle {
    margin-top: 1.5rem;
  }

  .inputContainer {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .inputFirstName{
    width: 15rem;
  }
  
  .inputEmail{
    width: 15rem;
  }

  .modalButton {
    width: 17rem;
    height: 50px;
    font-size: 1.25rem;
  }

  .imageOverlay {
    height: 13.5rem;
  }
}
