.stepText {
  max-width: 320px;
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step {
  gap: 1rem;
  text-align: center;
  font-size: var(--font-size-11xl);
  color: var(--black-v2);
  font-family: var(--font-montserrat);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.stepIcon {
  width: 70px;
  height: 70px;
}

.stepIconContainer {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  background-color: var(--primary);
  display: flex;
  padding: var(--padding-mini);
  box-sizing: border-box;
  position: relative;
  z-index: 2;
}

.timeline {
  width: 90%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.4);
  top: 16%;
  left: 65%;
  position: absolute;
  z-index: 1;
}

.stepTitle {
  margin: 0;
  font-weight: 500;
}

.stepDesc {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .step {
    gap: 0.75rem;
  }  

  .timeline {
    display: none;
  }
}

@media (max-width: 600px) {
  .stepIcon {
    width: 40px;
    height: 40px;
  }

  .stepIconContainer {
    width: 70px;
    height: 70px;
  }

  .stepTitle {
    font-size: 1.125rem;
  }

  .stepDesc {
    font-size: 1rem;
  }
}
