:root {
  --font-size-5xl: 24px; /* Example value, adjust as needed */
  --gap-11xl: 20px; /* Example value, adjust as needed */
  --gap-xl: 10px; /* Example value, adjust as needed */
  --black-v2: #000000; /* Example value, adjust as needed */
  --font-montserrat: 'Montserrat', sans-serif; /* Example value, adjust as needed */
  --br-xl: 10px; /* Example value, adjust as needed */
  --secondary: #00bc85; /* Example value, adjust as needed */
  --font-size-5xl: 44px;
}

.desktopfinalversion {
  background-color: var(--white);
  text-align: center;
  color: var(--black-v2);
  font-family: var(--font-montserrat);
  overflow-x: hidden;
}

.firstPage{
  display: flex;
  flex-direction: column;
  max-height: 1080px;
  height: 100vh;
  padding-left: 6%;
  padding-right: 6%;
  max-width: 1400px;
  margin: auto;  
}

.firstpagecontent {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  font-size: 2.75rem; /* 44px */
  gap: 100px;
  height: 100%;
}

.leftSideFirstPageContent,
.textdescription {
  align-items: flex-start;
}
.textdescription {
  align-self: stretch;
  gap: 50px;
  font-size: var(--font-size-5xl);
  color: var(--black-v2);
}

.leftSideFirstPageContent {
  width: 100%;
  flex: 0 0 54%;
  max-width: 800px;
}


.phoneImage {
  width: 100%;
  height: auto;
}

.firstPageTitle {
  width: 100%;
  margin-bottom: 20px;
  letter-spacing: 0.1px;
  line-height: 110%;
  font-size: 2.75rem; /* 44px */
}

.firstPageTitleDifferentColor {
  font-style: italic;
  color: var(--secondary);
}

.classicText {
  font-weight: 300;
  font-family: var(--font-montserrat);
}
.boldText {
  font-weight: 500;
  font-family: var(--font-montserrat);
}

.ctasubtitle,
.firstpagedescription {
  letter-spacing: 0.1px;
  line-height: 24px;
  display: inline-block;
}
.ctasubtitle {
  width: 300px;
  line-height: 18px;
  font-weight: 300;
  margin-top: 10px;
}

.calltoaction,
.leftSideFirstPageContent,
.textdescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.calltoaction {
  align-items: center;
  text-align: center;
  font-size: 1rem; /* 16px */
}

/* Big screen Styles */
@media (min-width: 2000px) {
  .rightSideFirstPageContent {
    max-width: 40vw;
  }

}

/* Tablet Styles */
@media (max-width: 1200px) {

  .firstPage {
    max-height: fit-content;
    height: fit-content;
  }

  .firstpagecontent {
    margin: 80px 0 50px 0;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
  
  .leftSideFirstPageContent {
    text-align: center;
    align-items: center;
  }

  .rightSideFirstPageContent {
    margin: 0 20% 0 20%;
  }

  .textdescription{
    align-items: center;
  }
}

/* Mobile Styles */
@media (max-width: 600px) {
  .firstpagecontent {
    margin-top: 40px;
  }

  .firstPageTitle {
    font-size: 1.875rem; /* 30px */
  }
  
  .firstpagedescription {
    width: 100%;
    font-size: 1rem; /* 16px */
  }

  .rightSideFirstPageContent {
    margin: 0 8% 0 8%;
  }
  
  .ctasubtitle {
    font-size: 0.875rem; /* 14px */
  }

  .callToActionButton {
    width: 300px;
    height: 50px;
  }
}





.secondPageBackground {
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 3% 4% 6% 4%;
  background-color: #373D31;
  position: relative;
}

.secondpagecontent {
  display: flex;
  flex-direction: row;
  gap: 4rem; /* Ajustement de l'espacement entre les éléments */
  text-align: left;
  color: var(--white);
  max-width: 1400px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
}


.subtitles {
  font-size: 1.75rem; /* Ajustement de la taille de la police */
  margin: 0;
  font-weight: 300;
}

.problemContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem; /* Ajustement de l'espacement entre les éléments */
  margin-top: 20px;
}

/* Responsive design for tablets */
@media (max-width: 1250px) {

  .secondPageBackground {
    height: max-content;
  }

  .titleContainer {
    width: 100%;
  }
  
  .secondpagecontent {
    flex-direction: column;
    align-items: center; /* Center align items */
  }

  .subtitles {
    text-align: center; /* Center align text */
  }

  .problemContainer{
    margin-top: 0px;
  }
}

/* Responsive design for phones */
@media (max-width: 700px) {
  .subtitles {
    font-size: 1rem; /* 16px */
  }

  .titleContainer{
    padding-top: 5%;
  }

  .secondpagecontent{
    gap: 2rem; /* Ajustement de l'espacement entre les éléments */
  }

  .secondPageBackground{
    padding: 4%;
    padding-bottom: 15%;
  }

  .problemContainer{
    margin-top: 0px;
  }
}



.parentThirdPageContent {
  background-color: #373D31;
}

.thirdpagecontent {
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  color: var(--secondary);
  padding: 5% 0 5% 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.thirdpagecontent::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  background-color: #d0e3c3;
  top: 0;
  border-radius: 50%;
  z-index: -1;
}

.feelio {
  font-weight: 700;
  color: var(--secondary);
}

.solutionDescBold {
  font-size: 1.5rem; /* Ajustement de la taille de la police */
  font-weight: bold;
  width: 100%;
}

.solutionDescClassic {
  font-size: 1.5rem; /* Ajustement de la taille de la police */
  margin-bottom: 1.5%;
}

.thirdpagecontent,
.thirdpagetextcontent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.thirdpagetextcontent {
  max-width: 1400px;
  text-align: center;
  padding : 0% 4% 0% 4%;
  align-items: flex-start;
  gap: 14px;
  font-size: var(--font-size-5xl);
  color: var(--black-v2);
}

@media (max-width: 1024px) {
  .thirdpagecontent {
    padding-top: 7%;
  }
  
  .thirdpagetextcontent {
    width: 80%;
    gap: 2rem;
    text-align: center;
  }

  .solutionDescBold {
    font-size: 1.125rem; 
    width: 100%;
  }
  
  .solutionDescClassic {
    font-size: 1.125rem;
  }
}

@media (max-width: 600px) {
  .thirdpagecontent {
    padding-top: 8%;
  }

  .thirdpagetextcontent {
    width: 90%;
    gap: 1.5rem;
  }

  .solutionDescBold {
    font-size: 0.95rem; /* Ajustement de la taille de la police */
  }
  
  .solutionDescClassic {
    font-size: 0.875rem; /* Ajustement de la taille de la police */
  }
}

@media (max-width: 425px) {
  .thirdpagecontent {
    padding-top: 15%;
  }
}


.inclinateDiv {
  max-width: 100vw;
  background-color: #d0e3c3;
  border-bottom: 40px solid var(--secondary);
  border-right: 100vw solid transparent;
  position: relative;
}


.fourthPageContent{
  width: 100vw;
  background-color: var(--secondary);
  padding: 3% 0 3% 0;
  overflow: hidden;
}

.stepContainer {
  display: flex;
  flex-direction: row;
  gap: 4rem; /* Ajustement de l'espacement entre les éléments */
  justify-content: center;
}

@media (max-width: 1200px) {

  .fourthPageContent {
    height: 100%;
    padding-bottom: 10%;
  }

  .stepContainer {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .fourthPageContent {
    padding-bottom: 15%;
  }
}



.fifthpage {
  max-width: 1400px;
  margin: auto;
  padding: 3% 4% 3% 4%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1024px) {
  .fifthpage {
    padding: 4%;
    padding-top: 8%;
    padding-bottom: 15%;
  }

  .fifthpage {
    min-height: 0;
  }
}



/* All titles of section */
.title {
  font-size: 3.65rem;
  font-weight: 600;
  margin: 20px 0 80px 0;
}

.secondpagecontent .title {
  margin: 20px 0 20px 0;
  font-size: 3.65rem;
  font-weight: 600;
}

.thirdpagecontent .title {
  margin: 0 0 40px 0;
}

/* Responsive design for tablets */
@media (max-width: 1250px) {
  .title{
    font-size: 2.8rem;
    text-align: center;
  }

  .secondpagecontent .title {
    font-size: 2.8rem;
  }
}

/* Responsive design for phones */
@media (max-width: 700px) {
  .title {
    font-size: 2.1rem;
    margin-bottom: 40px;
  }

  .secondpagecontent .title {
    font-size: 2.1rem;
  }
  
}
